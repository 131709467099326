import React, { useState } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  Drawer,
  IconButton,
} from "@mui/material";
import search from "../../../assets/images/ProjectsPage/Search.svg";
import Filter from "../../../assets/images/ProjectsPage/Filter.svg";
import CloseIcon from "@mui/icons-material/Close";
import "./projectfilters.scss";
import { getUniqueFilterValues } from "./filterUtils";

const ProjectFilters = ({ onFilterChange, data1 }) => {
  const [filterSelections, setFilterSelections] = useState({
    searchQuery: "",
    category: "",
    skill: "",
    difficulty: "",
  });

  // State for actual applied filters
  const [appliedFilters, setAppliedFilters] = useState({
    searchQuery: "",
    category: "",
    skill: "",
    difficulty: "",
  });

  // State for handling drawer visibility
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleSelectionChange = (event) => {
    const { name, value } = event.target;
    const updatedSelections = { ...filterSelections, [name]: value };
    setFilterSelections(updatedSelections);
  };

  const handleSearchChange = (event) => {
    const searchQuery = event.target.value;
    const updatedSelections = { ...filterSelections, searchQuery };
    setFilterSelections(updatedSelections);
  };

  const applyFilters = () => {
    setAppliedFilters(filterSelections);
    onFilterChange(filterSelections);
  };

  const clearFilters = () => {
    const resetFilters = {
      searchQuery: "",
      category: "",
      skill: "",
      difficulty: "",
    };
    setFilterSelections(resetFilters);
    setAppliedFilters(resetFilters);
    onFilterChange(resetFilters);
  };

  // Use utility to extract unique values
  const { uniqueCategories, uniqueSkills, uniqueDifficulties } =
    getUniqueFilterValues(data1);

  return (
    <div className="filters-container">
      {/* Search bar */}
      <div className="search-bar-wrapper">
        <img src={search} alt="search" className="img-search" />
        <TextField
          placeholder="Search projects"
          variant="outlined"
          size="small"
          className="search-bar"
          fullWidth
          value={filterSelections.searchQuery}
          onChange={handleSearchChange}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        />
        <button className="filter-icon" onClick={toggleFilter}>
          <img src={Filter} alt="filter" className="image-filter" />
        </button>
      </div>

      {/* Desktop view filters */}
      <div className="desktop-filters">
        <div className="filter-group">
          <Select
            name="category"
            value={filterSelections.category}
            onChange={handleSelectionChange}
            className="filter-dropdown"
            size="small"
            displayEmpty
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <MenuItem value="">Categories</MenuItem>
            {uniqueCategories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
          <Select
            name="skill"
            value={filterSelections.skill}
            onChange={handleSelectionChange}
            className="filter-dropdown"
            size="small"
            displayEmpty
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <MenuItem value="">Skills</MenuItem>
            {uniqueSkills.map((skill) => (
              <MenuItem key={skill} value={skill}>
                {skill}
              </MenuItem>
            ))}
          </Select>
          <Select
            name="difficulty"
            value={filterSelections.difficulty}
            onChange={handleSelectionChange}
            className="filter-dropdown"
            size="small"
            displayEmpty
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <MenuItem value="">Difficulty Level</MenuItem>
            {uniqueDifficulties.map((difficulty) => (
              <MenuItem key={difficulty} value={difficulty}>
                {difficulty}
              </MenuItem>
            ))}
          </Select>
          <Button
            sx={{
              backgroundColor: "#333",
              color: "white",
              padding: "8px 16px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#444",
              },
            }}
            className="apply-button"
            onClick={applyFilters} // Apply filters on click
          >
            Apply
          </Button>
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid black",
              padding: "8px 16px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            className="clear-button"
            onClick={clearFilters}
          >
            Clear
          </Button>
        </div>
      </div>

      {/* Drawer for mobile filter panel */}
      <Drawer
        anchor="bottom"
        open={isFilterOpen}
        onClose={toggleFilter}
        PaperProps={{
          sx: { height: "70%" },
        }}
        sx={{
          display: { xs: "block", sm: "none" },
        }}
      >
        <div className="drawer-header">
          <IconButton onClick={toggleFilter}>
            <CloseIcon />
          </IconButton>
          <h3>Filter Projects</h3>
        </div>

        <div className="filter-content">
          <Select
            name="category"
            value={filterSelections.category}
            onChange={handleSelectionChange}
            className="filter-dropdown"
            size="small"
            displayEmpty
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <MenuItem value="">Categories</MenuItem>
            {uniqueCategories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
          <Select
            name="skill"
            value={filterSelections.skill}
            onChange={handleSelectionChange}
            className="filter-dropdown"
            size="small"
            displayEmpty
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <MenuItem value="">Skills</MenuItem>
            {uniqueSkills.map((skill) => (
              <MenuItem key={skill} value={skill}>
                {skill}
              </MenuItem>
            ))}
          </Select>
          <Select
            name="difficulty"
            value={filterSelections.difficulty}
            onChange={handleSelectionChange}
            className="filter-dropdown"
            size="small"
            displayEmpty
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <MenuItem value="">Difficulty Level</MenuItem>
            {uniqueDifficulties.map((difficulty) => (
              <MenuItem key={difficulty} value={difficulty}>
                {difficulty}
              </MenuItem>
            ))}
          </Select>

          <Button
            sx={{
              backgroundColor: "#333",
              color: "white",
              padding: "8px 16px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#444",
              },
            }}
            className="apply-button"
            onClick={() => {
              applyFilters();
              toggleFilter();
            }}
          >
            Apply
          </Button>
          <Button
            sx={{
              backgroundColor: "transparent",
              color: "#333",
              padding: "8px 16px",
              textTransform: "none",
              border: "1px solid #333",
            }}
            className="clear-button"
            onClick={clearFilters}
          >
            Clear Filters
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default ProjectFilters;
