import { Box, Button, Typography } from "@mui/material";
import Calender from "../../../assets/images/Workshops/Calendar.svg";
import banner1 from "../../../assets/images/Workshops/banner1.png";
// import banner2 from "../../../assets/images/Workshops/banner2.png";
// import banner3 from "../../../assets/images/Workshops/banner3.png";
// import banner4 from "../../../assets/images/Workshops/banner4.png";
import banner7 from "../../../assets/images/Workshops/banner7.png";
// import banner5 from "../../../assets/images/Workshops/banner5.png";
import banner6 from "../../../assets/images/Workshops/banner6.png";
import banner8 from "../../../assets/images/Workshops/banner8.png";
import banner9 from "../../../assets/images/Workshops/banner9.png";
import banner10 from "../../../assets/images/Workshops/banner10.png";
import banner11 from "../../../assets/images/Workshops/banner11.png";
import banner12 from "../../../assets/images/Workshops/banner12.png";
import banner16 from "../../../assets/images/Workshops/banner16.jpg";
import banner17 from "../../../assets/images/Workshops/banner17.jpg";
import banner18 from "../../../assets/images/Workshops/banner18.jpg";
import banner19 from "../../../assets/images/Workshops/banner19.png";
// import banner13 from "../../../assets/images/Workshops/banner13.jpg";
import banner14 from "../../../assets/images/Workshops/banner14.jpg";
import banner15 from "../../../assets/images/Workshops/banner15.jpg";
import banner20 from "../../../assets/images/Workshops/banner20.jpg";
import banner21 from "../../../assets/images/Workshops/banner21.jpg";
import banner22 from "../../../assets/images/Workshops/banner22.jpg";
import banner23 from "../../../assets/images/Workshops/banner23.jpg";
import banner24 from "../../../assets/images/Workshops/banner24.jpg";
import banner25 from "../../../assets/images/Workshops/banner25.jpg";
import banner26 from "../../../assets/images/Workshops/banner26.jpg";
import "../workshops/Workshops.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useRef, useEffect } from "react";

const allContent = [
  {
    bannerImg: banner26,
    workshop: "10 Day Flutter Boot Camp",
    mentor: "Narayan Vyas",
    date: "13/Oct/2024 - 22/Oct/2024 ",
    button: "Register Now",
    btnLink:
      "https://upskill.coursevita.com/flutter-app-development",
  },
  {
    bannerImg: banner24,
    workshop: "2 Day Android Development",
    mentor: "",
    date: "21/Sep/2024 - 22/Sep/2024",
    button: "Register Now",
    btnLink:
      "https://us06web.zoom.us/meeting/register/tZUodeGupjwvHN2HrhtrkssxwfMlTs7JNZyz",
  },
  // {
  //   bannerImg: banner25,
  //   workshop: "5 Day-DSA Course",
  //   mentor: "",
  //   date: "23/Sep/2024 - 27/Sep/2024",
  //   button: "Register Now",
  //   btnLink:
  //     "https://us06web.zoom.us/meeting/register/tZUscumhrjosHtQR4IBGtCVOZxbWv9yccxM7",
  // },
  {
    bannerImg: banner22,
    workshop: "5 Day -MLOPS Course",
    mentor: "Bharat Sreeram",
    date: "18/Sep/2024 -	21/Sep/2024",
    button: "Know more",
    btnLink:
      "https://us06web.zoom.us/meeting/register/tZElcOGsrDgqG9CmNeDsWzMY2LEzot5WS3ID",
  },

  {
    bannerImg: banner23,
    workshop: "AI Chatbot Development Workshop",
    mentor: "Revanth GH",
    date: "16/Sep/2024	20/Sep/2024",
    button: "Register Now",
    btnLink:
      "https://upskill.coursevita.com/ai-chatbot-development",
  },
    {
      bannerImg: banner19,
      workshop: "Master AWS Devops",
      mentor: "Vinayak Ojha",
      date: "2nd-18th Sep, 2024",
      button: "Register Now",
      btnLink:
        "https://us06web.zoom.us/meeting/register/tZMuf-qpqjorHdS9_mK10Dw2c3Bl3jWc_gd1",
    },
    {
      bannerImg: banner20,
      workshop: " 25 Day Frontend Course",
      mentor: "Narsimha",
      date: "02/09/2024 - 28/09/2024",
      button: "Register Now",
      btnLink:
        "https://us06web.zoom.us/meeting/register/tZcqcumoqjwrHN1HQzp96UWaUvQckDu0WKDQ",
    },
  
  {
    bannerImg: banner21,
    workshop: "25 Day Python Course",
    mentor: "Atharv Bhute",
    date: "09/09/2024 - 11/10/2024",
    button: "Know more",
    btnLink:
      "https://us06web.zoom.us/meeting/register/tZElc-mtrDMuHtwGTQ7Dh1iuU_RS26YYb_rX",
  },
  {
    bannerImg: banner18,
    workshop: "Build your customized chat bot using GEN AI",
    mentor: "Revanth Guthala",
    date: "25th August, 2024",
    button: "Know more",
    btnLink:
      "https://us06web.zoom.us/meeting/register/tZcvc-muqDIiEtCdnj8zm1Zm9I7S1TrK-LUR#/registration",
  },
  {
    bannerImg: banner16,
    workshop: "How to start your career in Data science?",
    mentor: "Nikanskha Jain",
    date: "24th-25th August, 2024",
    button: "Know more",
    btnLink:
      "https://us06web.zoom.us/meeting/register/tZ0pcOqoqzIpHNU2G4BOCTUKgM-MtvA2kWrY",
  },
  {
    bannerImg: banner14,
    workshop: "Excel For Data Analytics",
    mentor: "Viswaja Reddy",
    date: "20th July, 2024",
    button: "Know more",
    btnLink: "https://upskill.coursevita.com/excel-for-dataanalytics",
  },
  {
    bannerImg: banner15,
    workshop: "Python for Data Analytics",
    mentor: "Nikansha Jain",
    date: "24th July, 2024",
    button: "Know more",
    btnLink: "https://upskill.coursevita.com/python-data-analytics",
  },
  {
    bannerImg: banner1,
    workshop: "5 Day Google’s Looker Studio Cohort",
    mentor: "Hemanth Guthala",
    date: "5th-9th July, 2024",
    button: "Know more",
    btnLink: "https://upskill.coursevita.com/looker-studio-cohort",
  },

  {
    bannerImg: banner10,
    workshop: "Higher Education in Data science with data AI & ML",
    mentor: "Dr. Sanatan Sukhija",
    date: "02nd June, 2024",
    button: "Know more",
    btnLink: "http://upskill.coursevita.com/data-science-workshop",
  },
  {
    bannerImg: banner6,
    workshop: "Data journey with SQL & Tableau",
    mentor: "Baldev Bhat",
    date: "19th-21st June, 2024",
    button: "Know more",
    btnLink: "http://upskill.coursevita.com/data-viz-workshop",
  },
  {
    bannerImg: banner11,
    workshop: "Digital marketing",
    mentor: "Dr. Ashutosh",
    date: "1st June, 2024",
    button: "Know more",
    btnLink: "http://upskill.coursevita.com/digital-marketing-masterclass",
  },
  {
    bannerImg: banner9,
    workshop: "How to start your career in Data science",
    mentor: "Nikanskha Jain",
    date: "24th May, 2024",
    button: "Know more",
    btnLink: "http://upskill.coursevita.com/career-in-data-science",
  },
  {
    bannerImg: banner7,
    workshop: "Big data to big welfare",
    mentor: "Revanth Guthala",
    date: "27th April, 2024",
    button: "Know more",
    btnLink: "http://upskill.coursevita.com/bigdata-to-bigwelfare",
  },
  {
    bannerImg: banner8,
    workshop: "Data science in finance & banking",
    mentor: "Neha Gupta",
    date: "18th-19th April, 2024",
    button: "Know more",
    btnLink: "http://upskill.coursevita.com/data-science-masterclass",
  },
  
  
  {
    bannerImg: banner12,
    workshop: "Free JavaScript mastery",
    mentor: "Neha Gupta",
    date: "15th-20th April, 2024",
    button: "Know more",
    btnLink: "http://upskill.coursevita.com/javascript-mastery",
  },
  
];




const upComingContent = [
  {
    bannerImg: banner26,
    workshop: "10 Day Flutter Boot Camp",
    mentor: "Narayan Vyas",
    date: "13/Oct/2024 - 22/Oct/2024 ",
    button: "Register Now",
    btnLink:
      "https://upskill.coursevita.com/flutter-app-development",
  },
  {
    bannerImg: banner24,
    workshop: "2 Day Android Development",
    mentor: "",
    date: "21/Sep/2024 - 22/Sep/2024",
    button: "Register Now",
    btnLink:
      "https://us06web.zoom.us/meeting/register/tZUodeGupjwvHN2HrhtrkssxwfMlTs7JNZyz",
  },
  {
    bannerImg: banner25,
    workshop: "5 Day-DSA Course",
    mentor: "",
    date: "23/Sep/2024 - 27/Sep/2024",
    button: "Register Now",
    btnLink:
      "https://us06web.zoom.us/meeting/register/tZUscumhrjosHtQR4IBGtCVOZxbWv9yccxM7",
  },

  // {
  //   bannerImg: banner22,
  //   workshop: "5 Day -MLOPS Course",
  //   mentor: "Bharat Sreeram",
  //   date: "18/Sep/2024 -	21/Sep/2024",
  //   button: "Register Now",
  //   btnLink:
  //     "https://us06web.zoom.us/meeting/register/tZElcOGsrDgqG9CmNeDsWzMY2LEzot5WS3ID",
  // },

];

const Workshops = () => {
  const sliderRef = useRef(null);
  const [content, setContent] = useState(allContent);
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  }, [content]);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }
  const [currentWorkshops, setCurrentWorkshops] = useState(true);

  let settings = null;
  if (content.length < 3) {
    settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      prevArrow: <SamplePrevArrow />,
      nextArrow: <SampleNextArrow />,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 0,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  } else {
    settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      prevArrow: <SamplePrevArrow />,
      nextArrow: <SampleNextArrow />,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  }

  return (
    <Box className="workshops">
      <Box
        className="headerText"
        sx={{ px: "16px", pb: { xs: "30px", sm: "40px" } }}
      >
        <Typography
          className="text1"
          sx={{ pb: "5px", fontSize: { xs: "20px", sm: "32px" } }}
        >
          Workshops
        </Typography>
        <Typography
          className="text2"
          sx={{ fontSize: { xs: "20px", sm: "32px" } }}
        >
          Learn from the best with our workshops
        </Typography>
      </Box>
      <Box
        className="btnWrapper "
        sx={{ gap: { xs: "16px", sm: "24px" }, px: "16px" }}
      >
        <Button
          className="workshopBtn"
          sx={{
            width: { xs: "50%", sm: "326px" },
            height: { xs: "41px", sm: "68px" },
            fontSize: { xs: "14px", sm: "20px" },
            p: { sm: "20px" },
          }}
          style={{
            color: currentWorkshops ? "white" : "#0F002E",
            backgroundColor: currentWorkshops ? "#0F002E" : "white",
          }}
          onClick={() => {
            setCurrentWorkshops(true);
            setContent(allContent);
          }}
        >
          All Workshops
        </Button>
        <Button
          sx={{
            width: { xs: "50%", sm: "326px" },
            height: { xs: "41px", sm: "68px" },
            fontSize: { xs: "14px", sm: "20px" },
          }}
          className="workshopBtn"
          style={{
            color: !currentWorkshops ? "white" : "#0F002E",
            backgroundColor: !currentWorkshops ? "#0F002E" : "white",
          }}
          onClick={() => {
            setCurrentWorkshops(false);
            setContent(upComingContent);
          }}
        >
          Upcoming workshops
        </Button>
      </Box>
      <Box>
        <Box
          sx={{
            width: "90%",
            p: { md: "40px 32px ", xs: " 25px 16px" },
            margin: "auto",
            maxWidth: "1600px",
          }}
        >
          <Box>
            <Slider ref={sliderRef} {...settings}>
              {content.map((item, index) => (
                <Box
                  sx={{
                    height: "100%",
                    display: "grid",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    className="card-landing-workshop"
                    key={index}
                    sx={{
                      p: { sm: "24px", xs: "14px" },
                      maxWidth: { md: "380px !important", xs: "90%" },
                      m: "0 12px",
                    }}
                  >
                    <Box>
                      <Box
                        component="img"
                        src={item.bannerImg}
                        sx={{ width: "100%", borderRadius: "6px" }}
                      />
                      <Typography
                        className="workshopName"
                        sx={{
                          pb: "2px",
                          pt: "20px",
                          fontSize: { xs: "16px", sm: "20px" },
                        }}
                      >
                        {item.workshop}
                      </Typography>
                      <Typography
                        className="mentor"
                        sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                      >
                        {/* By {item.mentor} */}

                        {item.mentor ? `By ${item.mentor}` : (<br />)} 
                      </Typography>
                      {item.date && (
                        <Box
                          className="dateContainer"
                          sx={{ gap: { xs: "8px", sm: "11px" } }}
                        >
                          <Box
                            component="img"
                            src={Calender}
                            sx={{ height: "24px", width: "24px" }}
                          />
                          <Typography
                            className="date"
                            sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                          >
                            {item.date}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Button
                      variant="outlined"
                      className="registerBtn"
                      onClick={() => window.open(item.btnLink, "_blank")}
                      sx={{ height: { xs: "38px", sm: "48px" } }}
                    >
                      {item.button}
                    </Button>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Workshops;
