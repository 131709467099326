import React from "react";
import Navbar from "../commonComponents/navbar/Navbar";
import Footer from "../commonComponents/footer/Footer";
import TopLayer from "./topLayer/TopLayer";
import SecondLayer from "./secondLayer/SecondLayer";
import ThirdLayer from "./thirdLayer/ThirdLayer";
import LastLayer from "./lastLayer/LastLayer";
import { Box } from "@mui/material";
const Workshop = () => {
  return (
    <Box>
      <Navbar />
      <TopLayer />
      <SecondLayer />
      <ThirdLayer />
      <LastLayer />
      <Footer />
    </Box>
  );
};

export default Workshop;
