import { Box, Typography, Button } from "@mui/material";
import "./thirdLayer.scss";
import React from "react";
import ec2 from "../../../assets/images/workshop/ec2.png";
import dropDown from "../../../assets/images/workshop/dropDown.svg";
import download from "../../../assets/images/workshop/Download.svg";

const ThirdLayer = () => {
  const tempArray = [...Array(6)];
  const tempArray2 = [...Array(4)];
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography className="header-text-1-secondlayer-workshop">
        <span className="header-text-2-secondlayer-workshop">Learn with </span>
        top rated tools
      </Typography>
      <Box className="box-wrapper-1-thirdLayer-workshop">
        {tempArray.map((item) => (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="box-wrapper-2-thirdLayer-workshop"
          >
            <Box component="img" src={ec2}></Box>
            <Typography>AWS EC2</Typography>
          </Box>
        ))}

        {tempArray2.map((item, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={`box-wrapper-3-thirdLayer-workshop`}
            style={index === 0 ? { gridColumn: "2" } : {}}
          >
            <Box component="img" src={ec2}></Box>
            <Typography>AWS EC2</Typography>
          </Box>
        ))}
      </Box>
      <Typography width="700px" className="header-text-1-secondlayer-workshop">
        Here’s &nbsp;
        <span className="header-text-2-secondlayer-workshop">
          What You’ll Learn{" "}
        </span>
        in the 3-Hour Workshop
      </Typography>
      <Box className="box-wrapper-4-thirdlayer-workshop">
        {[
          "Setting Up AWS Infrastructure",
          "Setting Up AWS Infrastructure",
          "CI/CD Pipeline with Jenkins and AWS",
          "Setting Up AWS Infrastructure",
          "Setting Up AWS Infrastructure",
          "Setting Up AWS Infrastructure",
        ].map((item, index) => (
          <Button className="box-wrapper-5-thirdlayer-workshop">
            <Typography className="text-1-thirdlayer-workshop">
              Module {index + 1}
            </Typography>
            <Typography className="text-2-thirdlayer-workshop">
              {item}
            </Typography>
            <Box
              position="absolute"
              right="10px"
              component="img"
              src={dropDown}
            ></Box>
          </Button>
        ))}
      </Box>
      <Button className="btn-thirdLayer-workshop">
        <Box component="img" src={download}></Box> &nbsp; Download curriculum
      </Button>
    </Box>
  );
};

export default ThirdLayer;
