
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const AboutUs=()=>{
    const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
return(
    // <Box>
    //     <Navbar/>
    //     <Welcome/>
    //     <Empty/>
    //     <Middle/>
    //     <Bottom/>
    //     <Footer/>
    // </Box>
<>

    <Helmet>
        <title>About Us</title>
        <meta name="description" content="Learn more about our company and team." />
        <meta name="keywords" content="about us, company, team, mission" />
    </Helmet>

    

    <Box
      sx={{
        position: 'relative',
        paddingBottom: '56.25%', // 16:9 aspect ratio
        height: 0,
        overflow: 'hidden',
        maxWidth: '100%',
        background: '#000',
      }}
    >
      <Box
        component="iframe"
        src="https://upskill.coursevita.com/data-viz-workshop"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 0,
        }}
        allowFullScreen
        title="Wix Page"
      />
    </Box>
    </>
)
}

export default AboutUs;