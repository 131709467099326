import "./toplayer.scss";
import React from "react";
import { Box, Button, Typography } from "@mui/material";
import waterMark1 from "../../../assets/images/workshop/group.svg";
import calendar from "../../../assets/images/workshop/calendar.svg";
import clock from "../../../assets/images/workshop/clock.svg";
import waterMark2 from "../../../assets/images/workshop/watermark2.svg";
import waterMark3 from "../../../assets/images/workshop/abstract.svg";
import Tick from "../../../assets/images/workshop/tick.svg";
import girlImg from "../../../assets/images/workshop/girl.png";
import Linkedin from "../../../assets/images/workshop/linkedin-logo.svg";
import clockBlack from "../../../assets/images/workshop/clockBlack.svg";
import string from "../../../assets/images/workshop/Line-pattern.svg";

const TopLayer = () => {
  return (
    <Box className="wrapper-top-workshop-toplayer">
      <Typography className="header-text-1-workshop">
        Don't let AI over-power you! Become an{" "}
        <span className="header-text-2-workshop">AI-powered professional</span>
      </Typography>
      <Box
        className="watermark1-workshop"
        component="img"
        src={waterMark1}
      ></Box>
      <Box
        className="waterMark2-workshop"
        component="img"
        src={waterMark2}
      ></Box>
      <Typography className="text-1-workshop">
        Learn groundbreaking secrets to save 1000s of dollars, streamline work
        processes & supercharge your growth in 2024 with the GenAI 8.0 Workshop
      </Typography>

      <Box display="flex" gap="10px" marginTop="20px">
        {[
          { img: calendar, date_time: "18th, June, 2024" },
          { img: clock, date_time: "11:00 A.M." },
        ].map((item) => (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="5px"
            sx={{
              backgroundColor: "black",
              padding: "6px",
              width: "200px",
              borderRadius: "6px",
            }}
          >
            <Box component="img" src={item.img}></Box>
            <Typography color="white" fontFamily="Poppins">
              {item.date_time}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box
        marginTop="30px"
        width="90vw"
        height="auto"
        backgroundColor="rgb(33 33 33)"
        borderRadius="10px"
        position="relative"
        overflow="hidden"
        padding="30px"
        display="flex"
      >
        <Box
          flex="3"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box
            className="watermark3-workshop"
            component="img"
            src={waterMark3}
            position="absolute"
            zIndex="0"
            left="0"
            top="0"
          ></Box>

          <Typography
            position="relative"
            zIndex="1"
            className="text-2-workshop"
          >
            5-Day <span className="text-4-workshop">Aws Devops workshop</span>
          </Typography>
          <Typography className="text-3-workshop">by Anvita Shetty</Typography>
          <Typography className="text-3-workshop">
            6+ Yrs Exp in Data Industry,  Ex-ISB
          </Typography>
          <Box display="flex" gap="10px" flexWrap="wrap" marginTop="20px">
            {[
              "5+ Industry Projects",
              "Industry ready certification",
              "Career guidance",
              "Hands on experience",
            ].map((item) => (
              <Box
                className="box-subwrapper-1-workshop"
                position="relative"
                zIndex="1"
                display="flex"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="start"
                  width="280px"
                  gap="10px"
                >
                  <Box component="img" src={Tick}></Box>
                  <Typography color="white">{item}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box flex="1" position="relative" zIndex="1">
          <Box
            position="absolute"
            right="-30px"
            top="-32px"
            component="img"
            src={string}
          ></Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="10px"
            sx={{
              borderRadius: "23px",
              border: "1px solid #FFF",
              background: "#2B2D2C",
            }}
            padding="20px"
            width="max-width"
            position="relative"
            zIndex="1"
          >
            <Box
              position="relative"
              zIndex="1"
              component="img"
              width="100%"
              height="100%"
              src={girlImg}
            ></Box>
            <Button
              display="flex"
              justifyContent="center"
              alignItems="center"
              className="btn-topLayer-1-workshop"
            >
              <Box component="img" src={Linkedin}></Box>
              <Typography
                className="btn-2-workshop-topLayer"
                textTransform="none"
              >
                View Linkedin
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <Button className="btn-3-workshop-topLayer">
        Register now For ₹299 &nbsp;
        <span style={{ textDecorationLine: "line-through" }}> ₹3000 </span>
      </Button>
      <Typography className="text-5-workshop">Offer ends in</Typography>
      <Box display="flex" justifyContent="center" alignItems="center" gap="3px">
        <Box component="img" src={clockBlack}></Box>
        <Typography>00:00</Typography>
      </Box>
    </Box>
  );
};

export default TopLayer;
