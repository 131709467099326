import React from "react";
import { Box, Typography, Button } from "@mui/material";
import "./middle.scss";
import { useNavigate, useParams } from "react-router-dom";
import data1 from "../../../global/projects.json";

const Card = ({ targetFeaturedProjects }) => {
  const navigate = useNavigate();

  const { project_type } = useParams();

  //   let data = shuffleArray(data1);

  let data = data1.filter((item) => {
    return item.category === project_type;
  });

  const handleClick = (item) => {
    navigate(`/projects/interest/${item.id}`);
    console.log(item.title);
  };

  return (
    <Box sx={{ padding: "0px 10px" }}>
      <Typography
        ref={targetFeaturedProjects}
        className="header-middle-project"
        style={{ scrollMarginTop: "80px" }}
      >
        <span style={{ color: "black" }}>Featured </span> Projects
      </Typography>
      <Typography className="normal-middle-project">
        Find interesting projects and get started with an execution plan
      </Typography>
      <Box className="top-wrapper-middle-project">
        {data.map((item) => (
          <Box key={item.id} className="card-wrapper-middle-project">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {/* <Box
                component="img"
                height="20px"
                width="auto"
                src={item.logo}
              ></Box> */}
              <Box display="flex" gap="5px" alignItems="center"></Box>
            </Box>
            <Typography className="header-text-middle-project">
              {item.title}
            </Typography>
            <Typography className="normal-text-middle-project">
              {item.objective}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box>
                <Typography className="prerequisitesH-middle-project">
                  Prerequisites
                </Typography>
                <Typography className="prerequisites-middle-project">
                  {item.prerequisites.join(", ")}
                </Typography>
              </Box>
              <Button
                onClick={() => {
                  handleClick(item);
                }}
                className="btn-middle-project"
              >
                View Project
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Card;
