import { Box, Typography } from "@mui/material";
import "./secondLayer.scss";
import React from "react";
import Tick from "../../../assets/images/workshop/tick.svg";

const SecondLayer = () => {
  return (
    <Box>
      <Typography className="header-text-1-secondlayer-workshop">
        What will you learn{" "}
        <span className="header-text-2-secondlayer-workshop">
          in the workshop?
        </span>
      </Typography>

      <Box className="box-wrapper-2-secondlayer-workshop">
        {[
          "Gain Practical Skills Through Interactive Sessions And Real-World Examples",
          "Learn From Industry Experts With Extensive Experience In AWS and DevOps",
          "Connect With Like-Minded Professionals And Expand Your Network​",
          "Receive A Certificate To Showcase Your New Skills",
        ].map((item) => (
          <Box className="box-wrapper-1-secondlayer-workshop" display="flex">
            <Box component="img" src={Tick}></Box>
            <Typography>{item}</Typography>
          </Box>
        ))}
      </Box>

      <Typography className="header-text-2-secondlayer-workshop">
        Who{" "}
        <span className="header-text-1-secondlayer-workshop">
          This Masterclass Is For
        </span>
      </Typography>

      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        paddingTop="70px"
        paddingBottom="70px"
      >
        <Box position="relative" height="50vh">
          <Typography className="text-1-secondlayer-workshop">
            Students
          </Typography>
          <Typography className="text-2-secondlayer-workshop">
            IT operation professionals
          </Typography>
          <Typography className="text-3-secondlayer-workshop">
            Early Professionals
          </Typography>
          <Typography className="text-4-secondlayer-workshop">
            Tech Entrepreneurs
          </Typography>
          <Typography className="text-5-secondlayer-workshop">
            System administrators and developers
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SecondLayer;
