import "./App.css";
import LandingPage from "./components/landingPage";
import CoursePreviewPage from "./components/coursePreview";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BlogsPage from "./components/blog";
import coursesContent from "../src/global/courses.json";
import AboutUs from "./components/aboutUs";
import TermsPage from "./components/termsAndConditions";
import PrivacyPage from "./components/privacy";
import RefundPolicyPage from "./components/refundpolicy";
import blogsContent from "../src/global/blogs.json";
import images from "./global/images.json";
import BlogsPreviewPage from "./components/blogPreview";
import CheckoutPage from "./components/checkout";
import PaymentForm from "./components/payment/Payment";
import ProjectPage from "./components/projectPage";

import FindYourCareerPage from "./components/findYourCareer";
import CourseDescriptionPage from "./components/findYourCareer/courseDescription";
import PersonaAssessment from "./components/skillAssesment-test/stepOne/StepOne";

import CareerCounselling from "./components/mockInterview";
import MockGroupDiscussion from "./components/mockGroupDiscussion";
import Interest from "./components/projectPage/Interest/Interest";
import SkillAssessment from "./components/skillAssessment";
import Workshop from "./components/workshop";

function App() {
  const coursesWithImages = coursesContent.map((course) => ({
    ...course,
    imageSource: images.courseImages[course.imageKey],
  }));
  const blogsWithImages = blogsContent.map((blog) => ({
    ...blog,
    image: images.blogsImages[blog.imageKey],
  }));

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<LandingPage coursesContent={coursesWithImages} />}
        />
        <Route
          path="/courses/:courseName"
          element={<CoursePreviewPage coursesContent={coursesWithImages} />}
        />
        <Route
          path="/blogs/:blogPreview"
          element={<BlogsPreviewPage blogsContent={blogsWithImages} />}
        />
        <Route
          path="/blogs"
          element={
            <BlogsPage
              coursesContent={coursesWithImages}
              blogsContent={blogsWithImages}
            />
          }
        />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
        <Route
          path="/checkout/:courseName"
          element={<CheckoutPage coursesContent={coursesWithImages} />}
        />
        <Route path="/refund-policy" element={<RefundPolicyPage />} />
        <Route
          path="/workshops/data_visualisation/payment"
          element={<PaymentForm />}
        />
        <Route path="/projects" element={<ProjectPage />} />
        <Route path="/projects/:project_type" element={<ProjectPage />} />
        <Route
          path="/projects/interest/:interest_type"
          element={<Interest />}
        ></Route>
        <Route path="/careerCounselling" element={<CareerCounselling />} />
        <Route path="/findYourcareer" element={<FindYourCareerPage />} />

        <Route
          path="/findYourcareer/:programId"
          element={<CourseDescriptionPage />}
        />
        <Route path="/mockGroupDiscussion" element={<MockGroupDiscussion />} />

        <Route path="/persona-assesment" element={<PersonaAssessment />} />
        <Route
          path="/findYourcareer/:programId"
          element={<CourseDescriptionPage />}
        />
        <Route path="/skillAssessment" element={<SkillAssessment />} />
        <Route path="/workshop" element={<Workshop />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
