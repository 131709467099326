import React from "react";
import Box from "@mui/material/Box";
import { Typography, Button, Card, CardContent } from "@mui/material";
import { useParams } from "react-router-dom";
import leftImage from "../../../assets/images/ProjectsPage/left_image.svg";
import rightImage from "../../../assets/images/ProjectsPage/right_image.svg";
import { CheckCircle } from "@mui/icons-material";
import logo1 from "../../../assets/images/ProjectsPage/logov1.svg";
import logo2 from "../../../assets/images/ProjectsPage/logov2.svg";
import smallImage from "../../../assets/images/ProjectsPage/small_image.svg";

function Header({ targetFeaturedProjects }) {
  const { project_type } = useParams();

  const mainRouteData = {
    title: "Are you a final-year student ready to build next-level projects?",
    description:
      "Yes! You are in the right place! Tackle real-world challenges, boost your portfolio, and gain hands-on experience with emerging tech.",
  };

  const dataToDisplay = mainRouteData;

  return (
    <Box
      sx={{
        background: "#ffffff",
        borderRadius: "20px",
        padding: { xs: "20px", md: "50px" },
        margin: { xs: "20px", md: "35px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {/* Title and Description Section */}
      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          justifyContent: "space-between",
          width: "100%",
          padding: "10px",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            flex: "0 0 70%",
            fontSize: { xs: "24px", md: "36px" },
            color: "#260079",
            fontWeight: "600",
            fontFamily: "Outfit",
            marginBottom: { xs: "10px", md: "0" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          {dataToDisplay.title}
          {/* Points Section */}
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              justifyContent: "flex-start",
              width: "100%",
              marginTop: "20px",
              marginBottom: "0",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                marginRight: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CheckCircle
                fontSize="small"
                sx={{ color: "#260079", marginRight: "8px" }}
              />
              Real-Time Project Experience
            </Typography>

            <Typography
              variant="body1"
              sx={{
                marginRight: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CheckCircle
                fontSize="small"
                sx={{ color: "#260079", marginRight: "8px" }}
              />
              Expert Guidance
            </Typography>

            <Typography
              variant="body1"
              sx={{
                marginRight: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CheckCircle
                fontSize="small"
                sx={{ color: "#260079", marginRight: "8px" }}
              />
              Collaborative Environment
            </Typography>
          </Box>
          {/* Small Image with Text Section */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
              width: "100%",
            }}
          >
            <img
              src={smallImage} // Use the small image here
              alt="Small Description"
              style={{ width: "40px", height: "40px", marginRight: "10px" }} // Adjust the size as needed
            />
            <Typography variant="body1" sx={{ color: "#555555" }}>
              Over 2000 have completed their Projects Successfully.
            </Typography>
          </Box>
        </Typography>

        {/* Description Box */}
        <Box
          sx={{
            flex: "0 0 30%",
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "14px", md: "18px" },
              fontFamily: "Outfit",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            {dataToDisplay.description}
          </Typography>

          {/* Button */}
          <Button
            onClick={() => {
              if (targetFeaturedProjects.current) {
                targetFeaturedProjects.current.scrollIntoView({
                  behavior: "smooth",
                });
              }
            }}
            sx={{
              backgroundColor: "#7234F7",
              backgroundImage:
                "linear-gradient(91deg, #7234F7 0%, #FE7465 130.98%)",
              color: "white",
              padding: { xs: "10px 15px", md: "15px 30px" },
              fontSize: { xs: "14px", md: "16px" },
              borderRadius: "7px",
              marginTop: { xs: "10px", md: "20px" },
              width: { xs: "100%", md: "auto" },
              "&:hover": {
                backgroundImage:
                  "linear-gradient(91deg, #7234F7 0%, #FE7465 120%)",
              },
            }}
          >
            Start Your Project
          </Button>
        </Box>
      </Box>

      {/* Images and Cards Section */}
      <Box
        sx={{
          display: { xs: "flex", md: "flex" },
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxWidth: "1200px", // Adjust this to limit overall width
          marginTop: { xs: "20px", md: "40px" },
          borderRadius: "20px",
          overflow: "hidden", // Prevent overflow
        }}
      >
        {/* Left Image */}
        <img
          src={leftImage}
          alt="Left Image"
          style={{
            maxWidth: "100%",
            height: "auto",
            borderRadius: "10px",
            boxShadow: "2",
          }}
        />

        {/* Cards Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flexGrow: "1",
            margin: { xs: "20px 0", md: "0 20px" },
            height: "350px", // Adjust this height as needed
          }}
        >
          {/* Card 1 */}
          <Card
            sx={{
              boxShadow: "3",
              borderRadius: "10px",
              background: "rgba(199, 199, 252, 0.4)", // Adjusted opacity
              flexGrow: "1",
              display: "flex",
              alignItems: "center",
              padding: "20px",
              marginBottom: "30px", // Spacing between cards
            }}
          >
            <img
              src={logo1}
              alt="Logo 1"
              style={{ marginRight: "20px", width: "70px", height: "70px" }}
            />
            <CardContent
              sx={{
                flexGrow: "1",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", marginBottom: "5px" }}
              >
                75%
              </Typography>
              <Typography variant="body1" align="left">
                Projects published in Portfolios.
              </Typography>
            </CardContent>
          </Card>

          {/* Card 2 */}
          <Card
            sx={{
              boxShadow: "3",
              borderRadius: "10px",
              background: "rgba(255, 200, 194, 0.4)", // Adjusted opacity
              flexGrow: "1",
              display: "flex",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <img
              src={logo2}
              alt="Logo 2"
              style={{ marginRight: "20px", width: "70px", height: "70px" }}
            />
            <CardContent
              sx={{
                flexGrow: "1",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", marginBottom: "5px" }}
              >
                80%
              </Typography>
              <Typography variant="body1" align="left">
                Learners report increased Confidence.
              </Typography>
            </CardContent>
          </Card>
        </Box>

        {/* Right Image */}
        <img
          src={rightImage}
          alt="Right Image"
          style={{
            maxWidth: "100%",
            height: "auto",
            borderRadius: "10px",
          }}
        />
      </Box>
    </Box>
  );
}

export default Header;
