import { Box, Typography, Button } from "@mui/material";
import "./lastLayer.scss";
import book from "../../../assets/images/workshop/Book.gif";
import qa from "../../../assets/images/workshop/QA.gif";
import clockBlack from "../../../assets/images/workshop/clockBlack.svg";
import google from "../../../assets/images/workshop/google-icon logo.svg";
import star from "../../../assets/images/workshop/Star.svg";
import user from "../../../assets/images/workshop/User.svg";
import tick from "../../../assets/images/workshop/tick.svg";

const LastLayer = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography width="700px" className="header-text-2-secondlayer-workshop">
        Register Before 4th September 2024 To Unlock{" "}
        <span className="header-text-1-secondlayer-workshop">
          Bonuses Worth ₹5,000
        </span>{" "}
      </Typography>
      <Box className="box-wrapper-1-lastlayer-workshop">
        {[
          {
            title: "Extended Learning Access",
            content:
              "Dive deeper and at your own pace! Get one-year access to all course materials, including videos and . Keep learning long after the course ends",
            img: book,
          },
          {
            title: "Monthly Expert Q&A Sessions",
            content:
              "Stay connected and supported. Join our exclusive monthly live Q&A sessions for a whole year. Bring your questions, discuss challenges, and explore advanced topics with the experts and your peers",
            img: qa,
          },
          {
            title: "Professional Certification",
            content:
              "Boost your professional profile. Complete the course and earn a Certificate of Completion to showcase your advanced skills in Aws Devops  on your resume and LinkedIn.",
            img: "",
          },
        ].map((item, index) => (
          <Box className="box-wrapper-2-lastlayer-workshop">
            <Box flex="2">
              <Typography className="text-1-lastlayer-workshop">
                Bonus#{index + 1}
              </Typography>
              <Typography className="text-2-lastlayer-workshop">
                {item.title}
              </Typography>
              <Typography className="text-3-lastlayer-workshop">
                {item.content}
              </Typography>
            </Box>
            <Box flex="1">
              <Box width="200px" component="img" src={item.img}></Box>
            </Box>
          </Box>
        ))}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Button sx={{ width: "300px " }} className="btn-3-workshop-topLayer">
            Join Now
          </Button>
          <Typography sx={{ marginBottom: "5px" }} className="text-5-workshop">
            Offer ends in
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="3px"
        >
          <Box component="img" src={clockBlack}></Box>
          <Typography>00:00</Typography>
        </Box>
      </Box>
      <Typography className="header-text-2-secondlayer-workshop">
        <span className="header-text-1-secondlayer-workshop">
          Why we are best
        </span>{" "}
        at your learning Journey?
      </Typography>
      <Typography className="text-4-lastlayer-workshop">
        At CourseVita, we've upskilled over 2k+ students with expert-led
        workshops and courses in Product, Growth, Design, Tech, Data, and
        Business, all guided by top experts. Take your first step with us for
        your tomorrows future.
      </Typography>
      <Box display="flex" padding="20px 40px" gap="20px">
        <Box className="wrapper-1-lastlayer-workshop">
          <Box width="24px" component="img" src={google}></Box>
          <Box>
            <Typography className="text-5-lastlayer-workshop">
              Community Reviews
            </Typography>
            <Box display="flex" gap="3px">
              <Box width="15px" component="img" src={star}></Box>{" "}
              <Typography className="text-6-lastlayer-workshop">
                4.8/5 | 3k+ Ratings
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="wrapper-1-lastlayer-workshop">
          <Box width="24px" component="img" src={user}></Box>
          <Box>
            <Typography className="text-5-lastlayer-workshop">
              Learners 
            </Typography>
            <Box display="flex" gap="3px">
              {/* <Box width="15px" component="img" src={star}></Box>{" "} */}
              <Typography className="text-6-lastlayer-workshop">
                20k+
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Typography className="header-text-2-secondlayer-workshop">
        Master In{" "}
        <span className="header-text-1-secondlayer-workshop">AWS Devops</span>
      </Typography>

      <Box className="card-wrapper-lastlayer-workshop">
        <Typography className="text-7-lastlayer-workshop">₹299</Typography>
        <Typography className="text-8-lastlayer-workshop">
          <span style={{ textDecorationLine: "line-through", color: "black" }}>
            ₹1,999
          </span>{" "}
          (85% OFF)
        </Typography>
        <Typography className="text-9-lastlayer-workshop">
          Don't miss out! Secure your spot today
        </Typography>
        <Typography className="text-10-lastlayer-workshop">
          Exclusive content awaiting for you
        </Typography>
        {[
          "Hands-on projects",
          "Exclusive study materials",
          "Personalized guidance",
          "Gamified Learning",
          "Get certified by Industry experts",
        ].map((item, index) => (
          <Box
            display="flex"
            alignSelf="start"
            gap="10px"
            key={index}
            marginTop="5px"
          >
            <Box component="img" src={tick}></Box>
            <Typography clasName="text-11-lastlayer-workshop">
              {item}
            </Typography>
          </Box>
        ))}
        <Button
          sx={{ width: "300px ", alignSelf: "center" }}
          className="btn-3-workshop-topLayer"
        >
          Join Now
        </Button>
      </Box>
    </Box>
  );
};

export default LastLayer;
